/* Import all the font families "Roboto" */


@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@font-face {
    font-family: 'Kallisto';
    src: url("../../shared_components/public/assets/rebrand-fonts/Kallisto-Bold.otf") format("opentype");
}


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'IBM Plex Sans', sans-serif;
    color: #191c1f;
}

body::after{
    position:absolute;
    width:0;
    height:0;
    overflow:hidden;
    z-index:-1;
    content:url("../../shared_components/public/assets/rebrand-icons/verification-check-filled.svg");
}

h1,h2,h3,h4,h5,h6 {
    font-family: 'Kallisto', sans-serif;
    font-weight: 400;
}

h2 {
    font-size: 32px;
    color: #fff;
}

/* Putting bg images here so they get picked up by parcel */
/* TODO: Post release, bg + bg div needs some media queries to size it correctly */
.westbury-bg-login {
    background: url('../../shared_components/public/assets/new-signin-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;

}

/* BELOW CAN BE DELETED */
.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

/* unstyle default button */
button {
    all: unset;
}

/* FormBlocks */

.pageWraper {
    min-height: 100vh;
}

.logoSidebar {
    background: #e9e9e9;
}

.logoSidebar>img {
    display: block;
    max-width: 170px;
    margin-top: 15px;
    margin-left: 15px;
}

.formSubmitErros {
    font-size: 14px;
    color: #9f0101;
    margin: 0 0 20px;
}

.FormBlock {
    width: 100%;
    padding: 40px;
}

.signInBlock {
    text-align: center;
    max-width: 80%;
    margin: 25px auto 0;
    color: #000;
    font-size: 18px;
}

.signInBlock a {
    color: #000;
    text-decoration: none;
    font-weight: 600;
}

.signInBlock a:hover {
    color: #0a58ca;
}

.FormBlockInner {
    width: 90%;
    max-width: 660px;
    margin: auto;
    padding: 54px 54px 39px;
    background: #f0f0f0;
    border-radius: 10px;
    min-height: 470px;
}

.FormBlockWrapper h3 {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    margin: 0 0 12px;
    color: #191c1f;
}


/* Global CSS */

.form-check-inline {
    padding: 0;
    margin: 14px 0;
    margin-right: 16px;
}

.form-check {
    position: relative;
}

.styledRadioButtons input[type="radio"],
.styledCheckboxButtons input[type="checkbox"] {
    opacity: 0 !important;
    position: absolute;
    left: 8px;
    bottom: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 0 !important;
}

.styledRadioButtons input[type="radio"]+label,
.styledCheckboxButtons input[type="checkbox"]+label {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    line-height: 1;
}

.styledRadioButtons input[type="radio"]+label:before,
.styledCheckboxButtons input[type="checkbox"]+label:before {
    content: "";
    width: 17px;
    height: 17px;
    border: #191c1f 1px solid;
    border-radius: 4;
    margin-right: 5px;
    display: block;
    left: 0;
    background: #fdfdfd;
}

.styledRadioButtons input[type="radio"]+label:after {
    content: "";
    width: 11px;
    height: 11px;
    background-image: url("../../shared_components/public/assets/checkboxTick.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    display: block;
    position: absolute;
    top: 3px;
    left: 3px;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.styledRadioButtons input[type="radio"]:checked+label:after {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.styledCheckboxButtons input[type="checkbox"]+label:after {
    content: "";
    width: 11px;
    height: 11px;
    background-image: url("../../shared_components/public/assets/checkboxTick.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    display: block;
    position: absolute;
    top: 3px;
    left: 3px;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.styledCheckboxButtons input[type="checkbox"]:checked+label:after {
    -webkit-transform: scale(1);
    transform: scale(1);
}

/* .btn-primary,
.btn {
    background: #c4c4c4;
    border-radius: 24.5px;
    border: 1px solid #c4c4c4;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #5b5b5b;
    padding: 13px 30px;
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #5b5b5b;
    background-color: #c4c4c4;
    border-color: #c4c4c4;
} */

button.formStepButton {
    width: 80px;
    min-height: 32px;
    background-color: #c4c4c4 !important;
    background-image: url("../../shared_components/public/assets/Arrow.svg");
    background-position: 75%;
    background-repeat: no-repeat;
    background-size: 28px;
    margin-top: 19px;
    margin-left: auto;
    padding: 0;
    font-size: 0;
    display: block;
    border: 0 !important;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.SignUpWrap button[type="submit"] {
    width: 100%;
    margin-top: 40px;
}

.DeclarationText {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #5b5b5b;
    max-width: 260px;
    margin: 20px auto 0;
}

.form-control {
    font-size: 14px;
    color: #5b5b5b;
}

.groupLabel:empty {
    display: none !important;
}

.and-or {
    line-height: 16px;
}

.form-select,
.form-select:disabled {
    padding-left: 0px;
}

.form-control:focus-within {
    border: var(--color-charcoal) 1px solid;
    border-radius: 5px;
    box-shadow: none;
}

.form-control:focus {
    box-shadow: none;
    border: var(--color-charcoal) 1px solid;
    border-radius: 5px;
}

.form-control:not(textarea),
.form-select {
    height: 30px;
    background-color: #fdfdfd;
    border: 000 1px solid;
    border-radius: 5;
    margin: 3px 0 0;
    padding: 0 0px 0px 10px;
    line-height: 30px;
    font-size: 16px;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

input:-webkit-autofill:focus {
    -webkit-box-shadow: /*your box-shadow*/
    , 0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
}

.alButton.btn {
    width: 23px;
    height: 23px;
    padding: 0;
    color: #fff;
    font-size: 20px;
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: 8px;
}

.btn-box {
    margin-top: 3px;
    margin-left: 4px;
}

.aliasesList+.aliasesList {
    margin-top: 10px;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #030304;
    border-color: #030304;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus,
.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.25rem rgba(3, 3, 4, 0.25);
    box-shadow: 0 0 0 0.25rem rgba(3, 3, 4, 0.25);
}

.otherSignUpButtons {
    margin-left: -8px;
    margin-right: -8px;
}

.otherSignUpButtons>[class*="col-"] {
    padding: 8px;
}

.btn.btn-secondary {
    background: #fff;
    border: 1px solid #fff;
}

.btn.btn-secondary:hover {
    background: #c4c4c4;
    color: #000;
}

.form-control::-webkit-input-placeholder {
    color: #ccc9cd;
}

.form-control:-ms-input-placeholder {
    color: #ccc9cd;
}

.form-control::-ms-input-placeholder {
    color: #ccc9cd;
}

.form-control::placeholder {
    color: #ccc9cd;
}

.fieldError {
    font-size: 14px;
    color: #e40000;
    margin: 10px 0;
}

button.ModalClose {
    display: block;
    width: 16px;
    height: 16px;
    padding: 0;
    font-size: 0;
    line-height: 0;
    background-color: transparent !important;
    background-image: url("../../shared_components/public/assets/close.svg");
    border-radius: 0;
    border: 0;
    position: absolute;
    right: 20px;
    top: 20px;
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    z-index: 999;
}



.groupLabel-lg {
    font-size: 18px;
}

.uploadicon {
    display: block;
    width: 19px;
    height: 24px;
    padding: 0;
    font-size: 0;
    line-height: 0;
    background-color: transparent !important;
    background-image: url("../../shared_components/public/assets/upload.svg");
    border-radius: 0;
    border: 0;
    background-size: 19px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.fileUploadLabel>.uploadicon {
    margin-left: auto;
    align-self: center;
}

.fileUpload>label>span.fileName {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

button.uploadButton {
    background: #000;
    color: #fff;
    display: flex;
    margin-left: auto;
    margin-right: 0;
    border-radius: 8px;
    padding: 8px 20px;
    border: 0;
    line-height: 1;
    align-items: center;
}

button.uploadButton>.uploadicon {
    margin-right: 16px;
    align-self: center;
}

.form-select {
    color: #ccc9cd;
}

.form-select.roleSelected,
.form-select option {
    color: #000000;
    border-bottom: 1px solid;
}

.optText {
    color: #ccc9cd;
}

.aliasesLabel {
    padding-right: 36px;
}

.ModalFormGroup .form-control {
    border: 1px solid#ccc9cd;
}

.searchControl {
    color: #191c1f;
    width: 220px;
    background: none;
    border: 0;
    border-bottom: 1px solid #191c1f;
    padding: 5px 30px 5px 0;
    font-size: 16px;
    line-height: 1.2;
    box-shadow: none !important;
    outline: none;
}

button.searchButton {
    width: 30px;
    height: 100%;
    background-color: transparent;
    background-image: url("../../shared_components/public/assets/search_icon.svg");
    border: none;
    padding: 0;
    font-size: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

button.searchButton.Light {
    background-image: url("../../shared_components/public/assets/search_icon_white.svg");
}

.p-relative {
    position: relative;
}

input.readonlyField {
    border: 0;
    height: auto;
    width: 100%;
    background-color: transparent !important;
}


/* End of css */