i.wi {
  display: inline-block;
  vertical-align: middle;
  background-color: currentColor;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

i.wi-xs {
  width: 1rem;
  height: 1rem;
}

i.wi-sm {
  width: 1.5rem;
  height: 1.5rem;
}

i.wi-md {
  width: 2.5rem;
  height: 2.5rem;
}

i.wi-lg {
  width: 4rem;
  height: 4rem;
}

i.wi-xl {
  width: 6rem;
  height: 6rem;
}

i.wi-role-accordion {
  -webkit-mask-image: url(public/assets/role-icons/accordion.svg);
  mask-image: url(public/assets/role-icons/accordion.svg);
}

i.wi-role-acoustic-bass {
  -webkit-mask-image: url(public/assets/role-icons/acoustic-bass.svg);
  mask-image: url(public/assets/role-icons/acoustic-bass.svg);
}

i.wi-role-acoustic-guitar {
  -webkit-mask-image: url(public/assets/role-icons/acoustic-guitar.svg);
  mask-image: url(public/assets/role-icons/acoustic-guitar.svg);
}

i.wi-role-alphorn {
  -webkit-mask-image: url(public/assets/role-icons/alphorn.svg);
  mask-image: url(public/assets/role-icons/alphorn.svg);
}

i.wi-role-autoharp {
  -webkit-mask-image: url(public/assets/role-icons/autoharp.svg);
  mask-image: url(public/assets/role-icons/autoharp.svg);
}

i.wi-role-bagpipes {
  -webkit-mask-image: url(public/assets/role-icons/bagpipes.svg);
  mask-image: url(public/assets/role-icons/bagpipes.svg);
}

i.wi-role-banjo {
  -webkit-mask-image: url(public/assets/role-icons/banjo.svg);
  mask-image: url(public/assets/role-icons/banjo.svg);
}

i.wi-role-bass-clarinet {
  -webkit-mask-image: url(public/assets/role-icons/bass-clarinet.svg);
  mask-image: url(public/assets/role-icons/bass-clarinet.svg);
}

i.wi-role-bass-drum {
  -webkit-mask-image: url(public/assets/role-icons/bass-drum.svg);
  mask-image: url(public/assets/role-icons/bass-drum.svg);
}

i.wi-role-bass-guitar {
  -webkit-mask-image: url(public/assets/role-icons/bass-guitar.svg);
  mask-image: url(public/assets/role-icons/bass-guitar.svg);
}

i.wi-role-bassoon {
  -webkit-mask-image: url(public/assets/role-icons/bassoon.svg);
  mask-image: url(public/assets/role-icons/bassoon.svg);
}

i.wi-role-bell {
  -webkit-mask-image: url(public/assets/role-icons/bell.svg);
  mask-image: url(public/assets/role-icons/bell.svg);
}

i.wi-role-bells {
  -webkit-mask-image: url(public/assets/role-icons/bells.svg);
  mask-image: url(public/assets/role-icons/bells.svg);
}

i.wi-role-bombo {
  -webkit-mask-image: url(public/assets/role-icons/bombo.svg);
  mask-image: url(public/assets/role-icons/bombo.svg);
}

i.wi-role-bongos {
  -webkit-mask-image: url(public/assets/role-icons/bongos.svg);
  mask-image: url(public/assets/role-icons/bongos.svg);
}

i.wi-role-brass {
  -webkit-mask-image: url(public/assets/role-icons/brass.svg);
  mask-image: url(public/assets/role-icons/brass.svg);
}

i.wi-role-cabasa {
  -webkit-mask-image: url(public/assets/role-icons/cabasa.svg);
  mask-image: url(public/assets/role-icons/cabasa.svg);
}

i.wi-role-castanets {
  -webkit-mask-image: url(public/assets/role-icons/castanets.svg);
  mask-image: url(public/assets/role-icons/castanets.svg);
}

i.wi-role-cello {
  -webkit-mask-image: url(public/assets/role-icons/cello.svg);
  mask-image: url(public/assets/role-icons/cello.svg);
}

i.wi-role-chimes {
  -webkit-mask-image: url(public/assets/role-icons/chimes.svg);
  mask-image: url(public/assets/role-icons/chimes.svg);
}

i.wi-role-clarinet {
  -webkit-mask-image: url(public/assets/role-icons/clarinet.svg);
  mask-image: url(public/assets/role-icons/clarinet.svg);
}

i.wi-role-claves {
  -webkit-mask-image: url(public/assets/role-icons/claves.svg);
  mask-image: url(public/assets/role-icons/claves.svg);
}

i.wi-role-conductor {
  -webkit-mask-image: url(public/assets/role-icons/conductor.svg);
  mask-image: url(public/assets/role-icons/conductor.svg);
}

i.wi-role-congas {
  -webkit-mask-image: url(public/assets/role-icons/congas.svg);
  mask-image: url(public/assets/role-icons/congas.svg);
}

i.wi-role-contrabassoon {
  -webkit-mask-image: url(public/assets/role-icons/contrabassoon.svg);
  mask-image: url(public/assets/role-icons/contrabassoon.svg);
}

i.wi-role-contributor {
  -webkit-mask-image: url(public/assets/role-icons/contributor.svg);
  mask-image: url(public/assets/role-icons/contributor.svg);
}

i.wi-role-cornet {
  -webkit-mask-image: url(public/assets/role-icons/cornet.svg);
  mask-image: url(public/assets/role-icons/cornet.svg);
}

i.wi-role-cow-bell {
  -webkit-mask-image: url(public/assets/role-icons/cow-bell.svg);
  mask-image: url(public/assets/role-icons/cow-bell.svg);
}

i.wi-role-cymbal {
  -webkit-mask-image: url(public/assets/role-icons/cymbal.svg);
  mask-image: url(public/assets/role-icons/cymbal.svg);
}

i.wi-role-didgeridoo {
  -webkit-mask-image: url(public/assets/role-icons/didgeridoo.svg);
  mask-image: url(public/assets/role-icons/didgeridoo.svg);
}

i.wi-role-dj {
  -webkit-mask-image: url(public/assets/role-icons/DJ.svg);
  mask-image: url(public/assets/role-icons/DJ.svg);
}

i.wi-role-djembe {
  -webkit-mask-image: url(public/assets/role-icons/djembe.svg);
  mask-image: url(public/assets/role-icons/djembe.svg);
}

i.wi-role-double-bass {
  -webkit-mask-image: url(public/assets/role-icons/double-bass.svg);
  mask-image: url(public/assets/role-icons/double-bass.svg);
}

i.wi-role-drum-machine {
  -webkit-mask-image: url(public/assets/role-icons/drum-machine.svg);
  mask-image: url(public/assets/role-icons/drum-machine.svg);
}

i.wi-role-drumkit {
  -webkit-mask-image: url(public/assets/role-icons/drumkit.svg);
  mask-image: url(public/assets/role-icons/drumkit.svg);
}

i.wi-role-electric-guitar {
  -webkit-mask-image: url(public/assets/role-icons/electric-guitar.svg);
  mask-image: url(public/assets/role-icons/electric-guitar.svg);
}

i.wi-role-english-horn {
  -webkit-mask-image: url(public/assets/role-icons/english-horn.svg);
  mask-image: url(public/assets/role-icons/english-horn.svg);
}

i.wi-role-erhu {
  -webkit-mask-image: url(public/assets/role-icons/erhu.svg);
  mask-image: url(public/assets/role-icons/erhu.svg);
}

i.wi-role-finger-cymbals {
  -webkit-mask-image: url(public/assets/role-icons/finger-cymbals.svg);
  mask-image: url(public/assets/role-icons/finger-cymbals.svg);
}

i.wi-role-finger-snaps {
  -webkit-mask-image: url(public/assets/role-icons/finger-snaps.svg);
  mask-image: url(public/assets/role-icons/finger-snaps.svg);
}

i.wi-role-flute {
  -webkit-mask-image: url(public/assets/role-icons/flute.svg);
  mask-image: url(public/assets/role-icons/flute.svg);
}

i.wi-role-foot-stomps {
  -webkit-mask-image: url(public/assets/role-icons/foot-stomps.svg);
  mask-image: url(public/assets/role-icons/foot-stomps.svg);
}

i.wi-role-french-horn {
  -webkit-mask-image: url(public/assets/role-icons/french-horn.svg);
  mask-image: url(public/assets/role-icons/french-horn.svg);
}

i.wi-role-general {
  -webkit-mask-image: url(public/assets/role-icons/general.svg);
  mask-image: url(public/assets/role-icons/general.svg);
}

i.wi-role-glockenspiel {
  -webkit-mask-image: url(public/assets/role-icons/glockenspiel.svg);
  mask-image: url(public/assets/role-icons/glockenspiel.svg);
}

i.wi-role-gong {
  -webkit-mask-image: url(public/assets/role-icons/gong.svg);
  mask-image: url(public/assets/role-icons/gong.svg);
}

i.wi-role-grand-piano {
  -webkit-mask-image: url(public/assets/role-icons/grand-piano.svg);
  mask-image: url(public/assets/role-icons/grand-piano.svg);
}

i.wi-role-guiro {
  -webkit-mask-image: url(public/assets/role-icons/guiro.svg);
  mask-image: url(public/assets/role-icons/guiro.svg);
}

i.wi-role-guitar {
  -webkit-mask-image: url(public/assets/role-icons/guitar.svg);
  mask-image: url(public/assets/role-icons/guitar.svg);
}

i.wi-role-hand-bell {
  -webkit-mask-image: url(public/assets/role-icons/hand-bell.svg);
  mask-image: url(public/assets/role-icons/hand-bell.svg);
}

i.wi-role-hand-claps {
  -webkit-mask-image: url(public/assets/role-icons/hand-claps.svg);
  mask-image: url(public/assets/role-icons/hand-claps.svg);
}

i.wi-role-harmonium {
  -webkit-mask-image: url(public/assets/role-icons/harmonium.svg);
  mask-image: url(public/assets/role-icons/harmonium.svg);
}

i.wi-role-harp {
  -webkit-mask-image: url(public/assets/role-icons/harp.svg);
  mask-image: url(public/assets/role-icons/harp.svg);
}

i.wi-role-harpsichord {
  -webkit-mask-image: url(public/assets/role-icons/harpsichord.svg);
  mask-image: url(public/assets/role-icons/harpsichord.svg);
}

i.wi-role-horn {
  -webkit-mask-image: url(public/assets/role-icons/horn.svg);
  mask-image: url(public/assets/role-icons/horn.svg);
}

i.wi-role-jug {
  -webkit-mask-image: url(public/assets/role-icons/jug.svg);
  mask-image: url(public/assets/role-icons/jug.svg);
}

i.wi-role-kalimba {
  -webkit-mask-image: url(public/assets/role-icons/banjo.svg);
  mask-image: url(public/assets/role-icons/banjo.svg);
}

i.wi-role-kazoo {
  -webkit-mask-image: url(public/assets/role-icons/kazoo.svg);
  mask-image: url(public/assets/role-icons/kazoo.svg);
}

i.wi-role-kettle-drum {
  -webkit-mask-image: url(public/assets/role-icons/kettle-drum.svg);
  mask-image: url(public/assets/role-icons/kettle-drum.svg);
}

i.wi-role-keyboards {
  -webkit-mask-image: url(public/assets/role-icons/keyboards.svg);
  mask-image: url(public/assets/role-icons/keyboards.svg);
}

i.wi-role-keytar {
  -webkit-mask-image: url(public/assets/role-icons/keytar.svg);
  mask-image: url(public/assets/role-icons/keytar.svg);
}

i.wi-role-koto {
  -webkit-mask-image: url(public/assets/role-icons/koto.svg);
  mask-image: url(public/assets/role-icons/koto.svg);
}

i.wi-role-mandolin {
  -webkit-mask-image: url(public/assets/role-icons/mandolin.svg);
  mask-image: url(public/assets/role-icons/mandolin.svg);
}

i.wi-role-maracas {
  -webkit-mask-image: url(public/assets/role-icons/maracas.svg);
  mask-image: url(public/assets/role-icons/maracas.svg);
}

i.wi-role-melodica {
  -webkit-mask-image: url(public/assets/role-icons/melodica.svg);
  mask-image: url(public/assets/role-icons/melodica.svg);
}

i.wi-role-monochord {
  -webkit-mask-image: url(public/assets/role-icons/monochord.svg);
  mask-image: url(public/assets/role-icons/monochord.svg);
}

i.wi-role-mouth-organ {
  -webkit-mask-image: url(public/assets/role-icons/mouth-organ.svg);
  mask-image: url(public/assets/role-icons/mouth-organ.svg);
}

i.wi-role-oboe {
  -webkit-mask-image: url(public/assets/role-icons/oboe.svg);
  mask-image: url(public/assets/role-icons/oboe.svg);
}

i.wi-role-organ {
  -webkit-mask-image: url(public/assets/role-icons/organ.svg);
  mask-image: url(public/assets/role-icons/organ.svg);
}

i.wi-role-panpipes {
  -webkit-mask-image: url(public/assets/role-icons/panpipes.svg);
  mask-image: url(public/assets/role-icons/panpipes.svg);
}

i.wi-role-percussion {
  -webkit-mask-image: url(public/assets/role-icons/percussion.svg);
  mask-image: url(public/assets/role-icons/percussion.svg);
}

i.wi-role-piccolo {
  -webkit-mask-image: url(public/assets/role-icons/piccolo.svg);
  mask-image: url(public/assets/role-icons/piccolo.svg);
}

i.wi-role-pipe {
  -webkit-mask-image: url(public/assets/role-icons/pipe.svg);
  mask-image: url(public/assets/role-icons/pipe.svg);
}

i.wi-role-producer {
  -webkit-mask-image: url(public/assets/role-icons/producer.svg);
  mask-image: url(public/assets/role-icons/producer.svg);
}

i.wi-role-programming {
  -webkit-mask-image: url(public/assets/role-icons/programming.svg);
  mask-image: url(public/assets/role-icons/programming.svg);
}

i.wi-role-rainstick {
  -webkit-mask-image: url(public/assets/role-icons/rainstick.svg);
  mask-image: url(public/assets/role-icons/rainstick.svg);
}

i.wi-role-recorder {
  -webkit-mask-image: url(public/assets/role-icons/recorder.svg);
  mask-image: url(public/assets/role-icons/recorder.svg);
}

i.wi-role-sampler {
  -webkit-mask-image: url(public/assets/role-icons/sampler.svg);
  mask-image: url(public/assets/role-icons/sampler.svg);
}

i.wi-role-saxophone {
  -webkit-mask-image: url(public/assets/role-icons/saxophone.svg);
  mask-image: url(public/assets/role-icons/saxophone.svg);
}

i.wi-role-shaker {
  -webkit-mask-image: url(public/assets/role-icons/shaker.svg);
  mask-image: url(public/assets/role-icons/shaker.svg);
}

i.wi-role-sitar {
  -webkit-mask-image: url(public/assets/role-icons/sitar.svg);
  mask-image: url(public/assets/role-icons/sitar.svg);
}

i.wi-role-sitar {
  -webkit-mask-image: url(public/assets/role-icons/sitar.svg);
  mask-image: url(public/assets/role-icons/sitar.svg);
}

i.wi-role-sleigh-bells {
  -webkit-mask-image: url(public/assets/role-icons/sleigh-bells.svg);
  mask-image: url(public/assets/role-icons/sleigh-bells.svg);
}

i.wi-role-snare-drum {
  -webkit-mask-image: url(public/assets/role-icons/snare-drum.svg);
  mask-image: url(public/assets/role-icons/snare-drum.svg);
}

i.wi-role-sound-effects {
  -webkit-mask-image: url(public/assets/role-icons/sound-effects.svg);
  mask-image: url(public/assets/role-icons/sound-effects.svg);
}

i.wi-role-spoons {
  -webkit-mask-image: url(public/assets/role-icons/spoons.svg);
  mask-image: url(public/assets/role-icons/spoons.svg);
}

i.wi-role-steel-drum {
  -webkit-mask-image: url(public/assets/role-icons/steel-drum.svg);
  mask-image: url(public/assets/role-icons/steel-drum.svg);
}

i.wi-role-steel-guitar {
  -webkit-mask-image: url(public/assets/role-icons/steel-guitar.svg);
  mask-image: url(public/assets/role-icons/steel-guitar.svg);
}

i.wi-role-strings {
  -webkit-mask-image: url(public/assets/role-icons/strings.svg);
  mask-image: url(public/assets/role-icons/strings.svg);
}

i.wi-role-stylophone {
  -webkit-mask-image: url(public/assets/role-icons/stylophone.svg);
  mask-image: url(public/assets/role-icons/stylophone.svg);
}

i.wi-role-synthesizer {
  -webkit-mask-image: url(public/assets/role-icons/synthesizer.svg);
  mask-image: url(public/assets/role-icons/synthesizer.svg);
}

i.wi-role-talking-drum {
  -webkit-mask-image: url(public/assets/role-icons/talking-drum.svg);
  mask-image: url(public/assets/role-icons/talking-drum.svg);
}

i.wi-role-tambourine {
  -webkit-mask-image: url(public/assets/role-icons/tambourine.svg);
  mask-image: url(public/assets/role-icons/tambourine.svg);
}

i.wi-role-theremin {
  -webkit-mask-image: url(public/assets/role-icons/theremin.svg);
  mask-image: url(public/assets/role-icons/theremin.svg);
}

i.wi-role-triangle {
  -webkit-mask-image: url(public/assets/role-icons/triangle.svg);
  mask-image: url(public/assets/role-icons/triangle.svg);
}

i.wi-role-trombone {
  -webkit-mask-image: url(public/assets/role-icons/trombone.svg);
  mask-image: url(public/assets/role-icons/trombone.svg);
}

i.wi-role-trumpet {
  -webkit-mask-image: url(public/assets/role-icons/trumpet.svg);
  mask-image: url(public/assets/role-icons/trumpet.svg);
}

i.wi-role-tuba {
  -webkit-mask-image: url(public/assets/role-icons/tuba.svg);
  mask-image: url(public/assets/role-icons/tuba.svg);
}

i.wi-role-tubular-bells {
  -webkit-mask-image: url(public/assets/role-icons/tubular-bells.svg);
  mask-image: url(public/assets/role-icons/tubular-bells.svg);
}

i.wi-role-ukelele {
  -webkit-mask-image: url(public/assets/role-icons/ukelele.svg);
  mask-image: url(public/assets/role-icons/ukelele.svg);
}

i.wi-role-vibraphone {
  -webkit-mask-image: url(public/assets/role-icons/vibraphone.svg);
  mask-image: url(public/assets/role-icons/vibraphone.svg);
}

i.wi-role-vibraslap {
  -webkit-mask-image: url(public/assets/role-icons/vibraslap.svg);
  mask-image: url(public/assets/role-icons/vibraslap.svg);
}

i.wi-role-viola {
  -webkit-mask-image: url(public/assets/role-icons/viola.svg);
  mask-image: url(public/assets/role-icons/viola.svg);
}

i.wi-role-violin {
  -webkit-mask-image: url(public/assets/role-icons/violin.svg);
  mask-image: url(public/assets/role-icons/violin.svg);
}

i.wi-role-vocals {
  -webkit-mask-image: url(public/assets/role-icons/vocals.svg);
  mask-image: url(public/assets/role-icons/vocals.svg);
}

i.wi-role-vocoder {
  -webkit-mask-image: url(public/assets/role-icons/vocoder.svg);
  mask-image: url(public/assets/role-icons/vocoder.svg);
}

i.wi-role-vuvuzela {
  -webkit-mask-image: url(public/assets/role-icons/vuvuzela.svg);
  mask-image: url(public/assets/role-icons/vuvuzela.svg);
}

i.wi-role-washboard {
  -webkit-mask-image: url(public/assets/role-icons/washboard.svg);
  mask-image: url(public/assets/role-icons/washboard.svg);
}

i.wi-role-whistle {
  -webkit-mask-image: url(public/assets/role-icons/whistle.svg);
  mask-image: url(public/assets/role-icons/whistle.svg);
}

i.wi-role-wood-block {
  -webkit-mask-image: url(public/assets/role-icons/wood-block.svg);
  mask-image: url(public/assets/role-icons/wood-block.svg);
}

i.wi-role-woodwind {
  -webkit-mask-image: url(public/assets/role-icons/woodwind.svg);
  mask-image: url(public/assets/role-icons/woodwind.svg);
}

i.wi-role-xylophone {
  -webkit-mask-image: url(public/assets/role-icons/xylophone.svg);
  mask-image: url(public/assets/role-icons/xylophone.svg);
}

i.wi-role-zither {
  -webkit-mask-image: url(public/assets/role-icons/zither.svg);
  mask-image: url(public/assets/role-icons/zither.svg);
}
