:root {
  /* background colours */
  --color-core-white: #ffffff;
  --color--cloud: #e9e9e9;
  --color-grey: #e5e5e5;
  /* buttons */
  --color-scarlet: #ff1529;
  --color-orange-red: #ff7121;
  --gradient-red-orange: linear-gradient(
    360deg,
    var(--color-scarlet) 27.67%,
    var(--color-orange-red) 108.25%
  );
  --gradient-red-orange-2: linear-gradient(
    var(--color-scarlet) 27.67%,
    var(--color-orange-red) 108.25%
  );
  --color-transparent-red: rgba(255, 21, 41, 0.4);
  --color-granite: #7d7d7d;
  --gradient-granite-charcoal: linear-gradient(
    180deg,
    var(--color-granite) -3.23%,
    var(--color-charcoal) 58.06%
  );
  --color-grey-shadow: rgba(177, 177, 177, 0.15);
  --color-silver: #ccc9cd;
  --color-baltic-grey: #3a3f43;
  --color-brand-grey: #fdfdfd;
  --bs-input-gray: #ced4da;
  /* text */
  --color-charcoal: #191c1f;
  --color-black: #000;
  --color-transparent-black: rgba(0, 0, 0, 0.06);
  --color-shadow-black: (3, 3, 4, 0.25);
  --color-shingle-black: #030304;
  --color-stone: #5b5b5b;
  --color-green: #62b776;
  --color-grass-green: #27a044;
  --color-darker-green: #008000;
  --color-yellow: #ffb715;
  --color-sunflower: #ff9c07;
  --color-onyx-black: #212529;
  --color-red-bright: #ff0a0a;
  --color-light-red: #864848;
  /* form colors */
  --background-color: #c4c4c4;
  --color-error-red: #e40000;
  --color-burnt-red: #b31d1d;
  --color-pearl-grey: #f0f0f0;
  --color-oyster-grey: #b2afaf;
  --color-grey-misty: #f0f0f0;
  --color-light-misty-shadow: #ddd;
  --color-table-grey: #d8d8d7;
}
