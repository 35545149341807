i.wi {
  display: inline-block;
  vertical-align: middle;
  background-color: currentColor;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

i.wi-xs {
  width: 0.75rem;
  height: 0.75rem;
}

i.wi-sm {
  width: 1rem;
  height: 1rem;
}

i.wi-20px {
  width: 20px;
  height: 20px;
}

i.wi-md {
  width: 2.5rem;
  height: 2.5rem;
}

i.wi-lg {
  width: 4rem;
  height: 4rem;
}

i.wi-xl {
  width: 6rem;
  height: 6rem;
}

i.wi-edit {
  -webkit-mask-image: url(public/assets/rebrand-icons/edit.svg);
  mask-image: url(public/assets/rebrand-icons/edit.svg);
}

i.wi-chevron-down {
  -webkit-mask-image: url(public/assets/rebrand-icons/chevron-down.svg);
  mask-image: url(public/assets/rebrand-icons/chevron-down.svg);
}

i.wi-eye {
  -webkit-mask-image: url(public/assets/rebrand-icons/eye.svg);
  mask-image: url(public/assets/rebrand-icons/eye.svg);
}

i.wi-shut-eye {
  -webkit-mask-image: url(public/assets/rebrand-icons/shut-eye.svg);
  mask-image: url(public/assets/rebrand-icons/shut-eye.svg);
}

i.wi-facebook {
  -webkit-mask-image: url(public/assets/rebrand-icons/facebook.svg);
  mask-image: url(public/assets/rebrand-icons/facebook.svg);
}

i.wi-twitter {
  -webkit-mask-image: url(public/assets/rebrand-icons/twitter.svg);
  mask-image: url(public/assets/rebrand-icons/twitter.svg);
}
i.wi-linkedin {
  -webkit-mask-image: url(public/assets/rebrand-icons/linkedin.svg);
  mask-image: url(public/assets/rebrand-icons/linkedin.svg);
}

i.wi- {
  -webkit-mask-image: url(public/assets/rebrand-icons/speakers.svg);
  mask-image: url(public/assets/rebrand-icons/speakers.svg);
}

i.wi-settings {
  -webkit-mask-image: url(public/assets/rebrand-icons/settings.svg);
  mask-image: url(public/assets/rebrand-icons/settings.svg);
}

i.wi-users {
  -webkit-mask-image: url(public/assets/rebrand-icons/users.svg);
  mask-image: url(public/assets/rebrand-icons/users.svg);
}

i.wi-gbp {
  -webkit-mask-image: url(public/assets/rebrand-icons/gbp.svg);
  mask-image: url(public/assets/rebrand-icons/gbp.svg);
}

i.wi-home {
  -webkit-mask-image: url(public/assets/rebrand-icons/home.svg);
  mask-image: url(public/assets/rebrand-icons/home.svg);
}

i.wi-email {
  -webkit-mask-image: url(public/assets/rebrand-icons/email.svg);
  mask-image: url(public/assets/rebrand-icons/email.svg);
}

i.wi-speakers {
  -webkit-mask-image: url(public/assets/rebrand-icons/speakers.svg);
  mask-image: url(public/assets/rebrand-icons/speakers.svg);
}

i.wi-chevron-right {
  -webkit-mask-image: url(public/assets/rebrand-icons/chevron-right.svg);
  mask-image: url(public/assets/rebrand-icons/chevron-right.svg);
}

i.wi-alert {
  -webkit-mask-image: url(public/assets/rebrand-icons/alert.svg);
  mask-image: url(public/assets/rebrand-icons/alert.svg);
}

i.wi-export {
  -webkit-mask-image: url(public/assets/rebrand-icons/export.svg);
  mask-image: url(public/assets/rebrand-icons/export.svg);
}

i.wi-search {
  -webkit-mask-image: url(public/assets/rebrand-icons/search.svg);
  mask-image: url(public/assets/rebrand-icons/search.svg);
}

i.wi-alert-warning {
  -webkit-mask-image: url(public/assets/rebrand-icons/alert-warning.svg);
  mask-image: url(public/assets/rebrand-icons/alert-warning.svg);
}

i.wi-circle-check {
  -webkit-mask-image: url(public/assets/rebrand-icons/circle-check.svg);
  mask-image: url(public/assets/rebrand-icons/circle-check.svg);
}

i.wi-copy {
  -webkit-mask-image: url(public/assets/rebrand-icons/copy.svg);
  mask-image: url(public/assets/rebrand-icons/copy.svg);
}

i.wi-ban {
  -webkit-mask-image: url(public/assets/rebrand-icons/ban.svg);
  mask-image: url(public/assets/rebrand-icons/ban.svg);
}

i.wi-plus {
  -webkit-mask-image: url(public/assets/rebrand-icons/plus.svg);
  mask-image: url(public/assets/rebrand-icons/plus.svg);
}

i.wi-minus {
  -webkit-mask-image: url(public/assets/rebrand-icons/minus.svg);
  mask-image: url(public/assets/rebrand-icons/minus.svg);
}

i.wi-trash {
  -webkit-mask-image: url(public/assets/rebrand-icons/trash.svg);
  mask-image: url(public/assets/rebrand-icons/trash.svg);
}

i.wi-chevron-left {
  -webkit-mask-image: url(public/assets/rebrand-icons/chevron-left.svg);
  mask-image: url(public/assets/rebrand-icons/chevron-left.svg);
}

i.wi-check {
  -webkit-mask-image: url(public/assets/rebrand-icons/check.svg);
  mask-image: url(public/assets/rebrand-icons/check.svg);
}

i.wi-verification-check-filled {
  -webkit-mask-image: url(public/assets/rebrand-icons/verification-check-filled.svg);
  mask-image: url(public/assets/rebrand-icons/verification-check-filled.svg);
}
i.wi-close {
  -webkit-mask-image: url(public/assets/rebrand-icons/close.svg);
  mask-image: url(public/assets/rebrand-icons/close.svg);
}
i.wi-sort {
  -webkit-mask-image: url(public/assets/rebrand-icons/sort.svg);
  mask-image: url(public/assets/rebrand-icons/sort.svg);
}
i.wi-chevron-up {
  -webkit-mask-image: url(public/assets/rebrand-icons/chevron-up.svg);
  mask-image: url(public/assets/rebrand-icons/chevron-up.svg);
}
